export default {
  state: {
    tokenDecoded: {
      email: '',
      preferred_username: '',
      sub: ''
    },
    userName: '',
    accessToken: ''
  },
  getters: {
    logado(state) {
      return state.tokenDecoded != null
    },
    getTokenDecoded(state) {
      return state.tokenDecoded
    },
    getAccessToken(state) {
      return state.accessToken
    },
    getUserName(state) {
      const emailUsuario = state.tokenDecoded.email

      state.userName = emailUsuario.slice(0, emailUsuario.indexOf('@'))

      return state.userName
    }
  },
  mutations: {
    atualizarToken(state, newValue) {
      state.tokenDecoded = newValue
    },
    atualizarAccessToken(state, newValue) {
      state.accessToken = newValue
    },
    destruirToken(state) {
      state.tokenDecoded = null
      state.accessToken = ''
    },
    parseToken(state) {
      const base64Url = state.tokenDecoded.split('.')[1]
      const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
      const jsonPayload = decodeURIComponent(
        atob(base64)
          .split('')
          .map(function (c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
          })
          .join('')
      )
      state.tokenDecoded = JSON.parse(jsonPayload)
    }
  }
}
