<template>
  <div>
    <navbar></navbar>
    <router-view />
  </div>
  <the-Footer class="footer"></the-Footer>
</template>

<script>
import 'bulma/css/bulma.css'
import Navbar from './components/Navbar.vue'
import TheFooter from './components/TheFooter.vue'

export default {
  components: { Navbar, TheFooter }
}
</script>

<style>
@import './assets/styles/reset.css';
@import './assets/styles/padroes.css';
@import 'v-calendar/dist/style.css';

:root {
  background-color: var(--c-background-dark);
  color-scheme: dark;
}

#app {
  color: white;
}

::-webkit-scrollbar {
  width: 10px;
  background: #454545;
  border-radius: 20px;
}

::-webkit-scrollbar-thumb {
  background: #ebebeb;
  border-radius: 8px;
}

.footer {
  position: relative;
  top: 0.5rem;
}

html.mapa-expandido {
    overflow: hidden;
  }

html.bloqueia-overflow {
    overflow: hidden;
  }

@media screen and (min-width: 768px) {
  .footer {
    top: 2rem;
  }
}
</style>
