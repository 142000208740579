export const routes = [
  {
    path: '/agendar',
    name: 'Agendar',
    component: () => import('@/views/Agendar.vue')
  },
  {
    path: '/meus-agendamentos',
    name: 'Agendamentos',
    component: () => import('@/views/Agendamentos.vue')
  },
  {
    path: '/',
    name: 'Home',
    component: () => import('@/views/Home.vue')
  }
]
