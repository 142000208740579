import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import Keycloak from 'keycloak-js'
import { createApp } from 'vue'
import App from './App.vue'
import router from './router/index'
import { apiInterceptor } from './services/setupInterceptors'
import store from './store/index'

const initOptions = {
  url: process.env.VUE_APP_CONTAS_URL || '',
  realm: process.env.VUE_APP_CONTAS_REALM || '',
  clientId: process.env.VUE_APP_CONTAS_CLIENTID || ''
}

const keycloak = new Keycloak(initOptions)
const appUrlLocation = window.location.protocol + '//' + window.location.host + '/#/'

export const logout = () => keycloak.logout()

keycloak
  .init({
    onLoad: 'login-required',
    checkLoginIframe: false
  })
  .then((auth) => {
    if (auth) {
      createApp(App)
        .component('font-awesome-icon', FontAwesomeIcon)
        .use(router)
        .use(store)
        .mount('#app')

      store.commit('atualizarToken', keycloak.token)
      store.commit('atualizarAccessToken', keycloak.token)
      store.commit('parseToken')

      apiInterceptor()

      setTimeout(() => window.location.replace(appUrlLocation))
    } else {
      logout()
    }

    setInterval(() => {
      keycloak
        .updateToken(180)
        .then((refreshed) => {
          if (refreshed) {
            store.commit('atualizarToken', keycloak.token)
            store.commit('atualizarAccessToken', keycloak.token)
            store.commit('parseToken')
          }
        })
        .catch((erro) => console.error('Falha em atualizar o token', erro))
    }, 10000)
  })
  .catch((erro) => console.error('Falha ao autenticar com o Keycloak', erro))
