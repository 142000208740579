<template>
  <div>
    <nav
      class="navbar has-centered-menu is-fixed-top"
      role="navigation"
      aria-label="main navigation"
      data-cy="Navegacao-principal"
    >
      <div class="navbar-brand">
        <router-link :to="{ name: 'Home' }">
          <img
            id="Logo"
            src="../assets/img/Logo-openspace-white.svg"
            alt="logo-openspace"
          >
        </router-link>

        <button
          class="navbar-burger burger"
          aria-label="menu"
          aria-expanded="false"
          data-target="extension-menu-mobile"
          @click="toggleNav"
        >
          <span aria-hidden="true" />
          <span aria-hidden="true" />
          <span aria-hidden="true" />
        </button>
      </div>

      <div
        id="extension-menu-mobile"
        ref="menuMobile"
        class="navbar-menu"
        :class="{ 'is-active': activeNavMobile }"
      >
        <div class="navbar-start">
          <router-link
            :to="{ name: 'Home' }"
            class="navbar-item"
            @click="closeNav"
          >
            <i class="fa-solid fa-home icon-menu" />
            Home
          </router-link>
          <router-link
            :to="{ name: 'Agendar' }"
            class="navbar-item"
            @click="closeNav"
          >
            <i class="fa-solid fa-circle-plus icon-menu" />
            Agendar
          </router-link>
          <router-link
            :to="{ name: 'Agendamentos' }"
            class="navbar-item"
            @click="closeNav"
          >
            <i class="fa-solid fa-calendar-check icon-menu" />
            Meus agendamentos
          </router-link>
        </div>

        <div class="navbar-end">
          <a
            class="navbar-item"
            title="Sair"
            @click="logoutContas"
          >
            <i class="fa-solid fa-arrow-right-from-bracket" />
          </a>
        </div>
      </div>
    </nav>
    <div
      v-if="activeNavMobile"
      class="transparente-background"
      @click="closeNav"
    />
  </div>
</template>

<script>
import { logout } from '../main'

export default {
  data () {
    return {
      activeNavMobile: false,
      urlLogoutContas: process.env.VUE_APP_LOGOUT_CONTAS
    }
  },

  methods: {
    toggleNav () {
      this.activeNavMobile = !this.activeNavMobile

      if (this.activeNavMobile) {
        document.addEventListener('click', this.handleClickOutside)
      } else {
        document.removeEventListener('click', this.handleClickOutside)
      }
    },

    handleClickOutside (event) {
      const menu = this.$refs.menuMobile
      const burger = this.$el.querySelector('.navbar-burger')

      if (!menu.contains(event.target) && !burger.contains(event.target)) {
        this.activeNavMobile = false
        document.removeEventListener('click', this.handleClickOutside)
      }
    },

    closeNav () {
      this.activeNavMobile = false
      document.removeEventListener('click', this.handleClickOutside)
    },

    logoutContas () {
      logout()
    }
  }
}
</script>

<style scoped>
nav,
.navbar-menu,
.navbar-brand {
  background-color: var(--c-background-soft);
  border-bottom: 1px solid var(--c-roxo-medio);
}

.navbar.has-centered-menu .navbar-start {
  flex-grow: 1;
  justify-content: center;
}

#Logo {
  width: 50px;
  padding-top: 10px;
  margin-left: 10px;
}

button, a {
  color: white;
}

button, a:hover {
  background-color: transparent;
  color: var(--c-roxo-medio);
}

button, a:focus {
  background-color: transparent;
  color: white;
}

a:focus {
  border-bottom: 1px solid var(--c-roxo-claro);
}

.navbar-start {
  gap: 1.25rem;
}

.icon-menu {
  margin-right: 10px;
}

.transparente-background {
  position: fixed;
  top: 3.25rem;
  left: 0;
  width: 100%;
  height: calc(100% - 3.25rem);
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 10;
}

</style>
