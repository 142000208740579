<template>
  <footer>
    <div class="conteudo-footer">
      <p class="copy-message">
        &copy; 1989 - {{ anoAtual }} - Alterdata Software - Direitos Reservados
      </p>
      <a href="#" id="LogoAlterdata">
        <img src="../assets/img/logo-alterdata-branco.png" alt="logo Alterdata" />
      </a>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'TheFooter',

  setup() {
    const anoAtual = new Date().getFullYear()

    return {
      anoAtual
    }
  }
}
</script>

<style scoped>
footer {
  width: 100%;
  background-color: var(--c-roxo-medio);
  color: white;
  padding: 5px;
}

.conteudo-footer {
  display: flex;
  align-items: center;
  width: 100%;
}

.copy-message {
  width: 100%;
  margin-left: 15px;
  font-size: var(--font-text);
}

#LogoAlterdata {
  width: 150px;
  margin-top: 10px;
  margin-right: 5px;
}
</style>
